import { Layout, Tag, Modal } from "antd";
import React, { useState, useEffect } from "react";
import "./style/project.scss";
import {
  fetchUsers,
  ordersList,
  stopAllTrade,
  resetAll,
} from "./../API/dispatch";

export const Users = ({ setAuth }) => {
  const [userData, setUserData] = useState([]);
  const [total, setTotal] = useState(0);
  const [nav, setNav] = useState("dashboard");
  const [id, setId] = useState("null");
  const [orders, setOrders] = useState("null");
  const [activate, setActive] = useState(false);

  useEffect(() => {
    (async function fetch() {
      const data = await fetchUsers();
      if (data && nav === "dashboard") {
        setUserData(data);
        setActive(true);
      }
    })();
  }, [activate, nav]);

  useEffect(() => {
    if (id) {
      (async function fetch() {
        const data = await ordersList({ id });
        if (data) {
          setOrders(data);
        }
      })();
    }
  }, [id]);

  window.addEventListener("popstate", () => {
    setNav("dashboard");
    setId(null);
    window.history.pushState(null, null, document.URL);
  });

  useEffect(() => {
    const eventSource = new EventSource(
      "https://stockbot-ai-pl-sell.onrender.com/users/sse"
    );

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data?.message?.length) {
        setUserData(data.message);
        setTotal(data?.message?.reduce((a, b) => a + b?.total, 0) || 0);
      }
    };

    return () => {
      eventSource.close();
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setAuth(false);
  };

  const handleReset = async () => {
    await resetAll();
    return;
  };
  return (
    <>
      <div>
        <div className="header-main">
          <div class="square1 square">A</div>
          <div class="square2 square">I</div>
          <div class="letters letter">SB</div>
        </div>
      </div>
      <div className="dashboard-container">
        <div className="profit-viewer">
          <h3 className="total">Total P&l</h3>
          <h1 className={total >= 0 ? "profit" : "loss"}>
            {total >= 0 && "+"}
            {Number(+total?.toFixed(2)).toLocaleString("en-IN") || 0}
          </h1>
        </div>
        {nav === "dashboard" && (
          <div className="userViewer">
            {userData?.map((i) => {
              return (
                <div className="userContainer">
                  <div className="username">
                    <h3>{i?.username.toUpperCase()}</h3>
                    <div id="center-div">
                      <div
                        class={`bubble  ${i?.isLive ? "online" : "offline"}`}
                      >
                        <span
                          class={`bubble-outer-dot  ${
                            i?.isLive ? "online" : "offline"
                          }`}
                        >
                          <span
                            class={`bubble-inner-dot ${
                              i?.isLive ? "online" : "offline"
                            }`}
                          ></span>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3 className={(i?.total || 0) < 0 ? "loss" : "profit"}>
                      {i?.total >= 0 && "+"}{" "}
                      {i?.total
                        ? Number(+i?.total?.toFixed(2)).toLocaleString("en-IN")
                        : 0}
                    </h3>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {nav === "orders" && (
          <div className="userViewer">
            {userData?.map((i) => {
              return (
                <div
                  className="userContainer"
                  onClick={() => {
                    setId(i._id);
                    setNav("list");
                  }}
                >
                  <div className="username">
                    <h3>{i?.username.toUpperCase()}</h3>
                  </div>
                  <div>
                    <img
                      src="https://cdn3.iconfinder.com/data/icons/uni-one-original-arrow-interface-set-24px/24/Arrows-Forward-Chevron-Next-Arrow_right-right-arrow_symbol-64.png"
                      className="icons"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {nav === "list" && id && (
          <div className="userViewer">
            {orders?.map((i) => {
              return (
                <div className="userContainer">
                  <div className="username border">
                    <Tag color={i?.order === "BUY" ? "green" : "red"}>
                      {i?.order}-{i?.expiry}
                    </Tag>
                  </div>

                  <div className="username border">
                    <h3>
                      <Tag color="blue">
                        {" "}
                        {i?.variance}-{i?.type}
                      </Tag>
                    </h3>
                  </div>

                  <div className="username border">
                    <Tag color={i?.isLiveToTrade ? "green" : "red"}>
                      {i?.isLiveToTrade ? "Online" : "Offline"}
                    </Tag>
                  </div>
                  <div className="username border">
                    <Tag color={i?.status === "PENDING" ? "red" : "green"}>
                      {i?.status}-{i?.quantity}
                    </Tag>
                  </div>
                </div>
              );
            })}
            {!orders?.length && (
              <div className="userContainer">
                <div className="username border">
                  <Tag color={"red"}>NO ORDERS</Tag>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <Layout>
        <div className="footer">
          <button
            className="footer-button"
            onClick={() => {
              setNav("orders");
              setId(null);
            }}
          >
            <img
              src="https://cdn0.iconfinder.com/data/icons/advertising-filled-outline/64/product-orders-production-package-box-shipping_and_delivery-64.png"
              className="icons"
            />
          </button>

          <button
            className="footer-button"
            onClick={() => {
              Modal.confirm({
                title: "Please confirm Reset!",
                onOk: () => {
                  handleReset();
                  return;
                },
              });
            }}
          >
            <img
              src="https://cdn0.iconfinder.com/data/icons/interface-pack-24px/25/Essential-Web-App-UX-Interface-reset-512.png"
              className="icons"
            />
          </button>
          <button
            className="footer-button"
            onClick={() => {
              setNav("dashboard");
            }}
          >
            <img
              src="https://cdn2.iconfinder.com/data/icons/buno-ui-interface/32/__dashboard_grid_layout-1024.png"
              className="icons"
            />
          </button>
          <button
            className="footer-button"
            onClick={() => {
              Modal.confirm({
                title: "Please confirm Stop all the trades!",
                onOk: async () => {
                  await stopAllTrade();
                  setActive(false);
                  return;
                },
              });
            }}
          >
            <img
              src="https://cdn1.iconfinder.com/data/icons/everyday-2/64/x_cross_delete_stop-64.png"
              className="icons"
            />
          </button>
          <button
            className="footer-button"
            onClick={() => {
              Modal.confirm({
                title: "Please confirm Logout!",
                onOk: () => {
                  handleLogout();
                  return;
                },
              });
            }}
          >
            <img
              src="https://cdn4.iconfinder.com/data/icons/internet-security-flat-2/32/Internet_Security_entrance_exit_log_out_arrow-512.png"
              className="icons"
            />
          </button>
        </div>
      </Layout>
    </>
  );
};
export default Users;
