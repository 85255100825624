import { Router } from "./router";
import { BrowserView, MobileView } from "react-device-detect";

function App() {
  return (
    <>
      <MobileView>
        <Router />
        <h6></h6>
      </MobileView>
      <BrowserView>
        <h1 style={{ color: "white", textAlign: "center" }}>
          Will be Back soon
        </h1>
      </BrowserView>
    </>
  );
}

export default App;
